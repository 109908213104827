nav {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform: rotate(-90deg) translate3d(-50%,0,0);
  top: 15%;
  left: -6rem;
}
nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #575757;
}
nav a:hover {
  margin-right: 20px;
  text-decoration: none;
  color: #fdadfe;
}
nav a.active {
  color: #fdadfe;
}

@media screen and (max-width: 400px) {
  nav {
    left: -6rem;
  }
}
  