.displacement-sphere {
    position: fixed;
    width: 10vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    transition-property: opacity;
    transition-duration: 3s;
    transition-timing-function: "cubic-bezier(0.4, 0.0, 0.2, 1)";
}

.displacement-sphere--entering,
.displacement-sphere--entered {
    opacity: 1;
}

@media screen and (max-width: 400px) {
    .displacement-sphere {
        width: 10vw;
    }
  }
